<div class="world-ship-upload white-sheet-content">
    <form #form [formGroup]="formGroup" (ngSubmit)="onFormSubmitted($event, formGroup.value)">
        <div class="form-header-wrapper">
            <div class="form-header">
                <ng-container></ng-container>
            </div>
        </div>
        <div *ngIf="isEUUser" class="pd">
            <div class="notice-title"><strong>{{'worldShipUpload.importantNotice'|translate}}</strong></div>
            <p class="reminder">
                {{'worldShipUpload.helpTextForEU'|translate}}
                <a *ngIf="!isEUUser" href="mailto:{{'helpDeskEmail'|translate}}">{{'helpDeskEmail'|translate}}</a>
                <a *ngIf="userCountryCode === 'GB'"
                    href="mailto:{{'helpDeskEmailUK'|translate}}">{{'helpDeskEmailUK'|translate}}</a>
                <a *ngIf="userCountryCode === 'DE'"
                    href="mailto:{{'helpDeskEmailDE'|translate}}">{{'helpDeskEmailDE'|translate}}</a>
                <a *ngIf="userCountryCode === 'FR'"
                    href="mailto:{{'helpDeskEmailFR'|translate}}">{{'helpDeskEmailFR'|translate}}</a>
                <a *ngIf="userCountryCode === 'IT'"
                    href="mailto:{{'helpDeskEmailIT'|translate}}">{{'helpDeskEmailIT'|translate}}</a>
            </p>
        </div>
        <div [ngClass]="isEUUser ? 'form-body-ForEU' : 'form-body'">
            <div role="alert" class="alert-message" *ngIf="!isEUUser">
                <div class="notice-title"><strong>{{'worldShipUpload.importantNotice'|translate}}</strong></div>
                <p>
                    <!-- As of May 27, 2008, please use <strong>Reference 3</strong> to report the insured
                    value when using WorldShip.<br>
                    This change is required because the High Value Report generated by WorldShip
                    displays Reference 2. -->
                    {{'worldShipUpload.notice1'|translate}} <strong>{{'Reference'|translate}} 3</strong>
                    {{'worldShipUpload.notice2'|translate}}
                    <br>
                    {{'worldShipUpload.notice3'|translate}}
                </p>
            </div>

            <p class="select-reference-option"><span
                    *ngIf="!isEUUser">{{'worldShipUpload.selectReferenceOption'|translate}}</span></p>
            <mat-radio-group formControlName="reference">
                <mat-radio-button value="2" *ngIf="!isEUUser">{{'Reference'|translate}} 2</mat-radio-button>
                <mat-radio-button value="3">{{'Reference'|translate}} 3</mat-radio-button>
            </mat-radio-group>

            <p class="browse-worldship-export-file">{{'worldShipUpload.browseWorldShipExportFile'|translate}}:</p>
            <div class="file-selector-wrapper">
                <upsc-file-selector [label]="'worldShipUpload.chooseFile'|translate" [fileTypes]="'.csv'"
                    [isMultiple]="false" (fileSelectionChanged)="onFileSelectionChanged($event)"></upsc-file-selector>
                <span class="file-name">{{ fileName }}</span>
                <button *ngIf="fileName" mat-button color="warn" type="button"
                    (click)="removeFile($event)">{{'Remove'|translate}}
                </button>
            </div>


            <div [formGroup]="formGroup.controls.shipments">
                <div class="shipments-preview" *ngIf="worldShipShipments?.length">
                    <upsc-shipments-preview [formGroup]="formGroup.controls.shipments"
                        [user]="user"></upsc-shipments-preview>
                </div>
            </div>

            <div class="upload-file-actions">
                <div class="agreement">
                    <span class="material-symbols-outlined">info</span>
                    <span>
                        {{ 'reportShipments.submittingInfromation1' | translate}} <a href="javascript:;"
                            (click)="openTermsAndConditionsDialog($event)">{{ 'reportShipments.submittingInfromation2' | translate}}</a>
                    </span>
                </div>
                <button mat-raised-button type="submit" class="form-action upload-file" [disabled]="!isFormValid()">
                    {{'worldShipUpload.uploadFile'|translate}}
                </button>
            </div>
        </div>

        <div class="question-container" *ngIf="environment.feature.reportShipmentsBulkUpload">
            <h4>{{ 'reportShipments.haveQuestions' | translate }}</h4>
            <div class="download-butttons">
                <upsc-s3-file-downloader [bucketName]="instructionsFileBucket"
                    [buttonText]="'reportShipments.worldShipUploadInstructions' | translate" [fileName]="instructionsFileName"
                    [showDownloadIcon]="true"></upsc-s3-file-downloader>
                <upsc-s3-file-downloader [bucketName]="faqFileBucket" [buttonText]="'reportShipments.worldShipUploadFAQ' | translate"
                    [fileName]="faqFileName" [showDownloadIcon]="true"></upsc-s3-file-downloader>
            </div>
            <div class="key"> </div>
        </div>


        <div class="form-footer-wrapper">
            <div class="form-footer">
                <div class="form-actions">
                </div>
            </div>
        </div>
    </form>
</div>

<!-- <ng-template #header>
    <upsc-page-header [pageTitle]=" 'siteMenu.ship.worldShipUpload' | translate">
        <div page-actions>
            <button *ngIf="false" mat-raised-button type="button" class="page-action" (click)="resetForm($event)">
                {{'Reset'|translate}}
            </button>
        </div>
    </upsc-page-header>
</ng-template> -->